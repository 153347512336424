import {
  LinearProgress, Typography, Box, Link,
} from '@mui/material';
import { useParams } from '@reach/router';
import { useState, useCallback, useEffect } from 'react';

import { useGetReaderQuery } from '../../../../plugins/gatsby-plugin-redux/store/api/reader.api';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import toDate, { FULL_DATE_UTC_FORMAT } from '../../../helpers/toDate';
import Application from '../../app/Application';
import CircularProgress from '../../app/CircularProgress';
import Container from '../../app/Container';
import Content from '../../app/Content';
import SEO from '../../app/SEO';
import EpubReader from './Epub/EpubReader';
import PDFReader from './Pdf/PDFReader';
import ReaderError from './ReaderError';

const Reader = () => {
  const auth = useAuth();
  const { slug } = useParams<{ slug: string }>();

  const { data: book, isLoading, isSuccess } = useGetReaderQuery(slug);

  const [isReaderLoading, setIsReaderLoading] = useState(false);

  const onLoadSuccess = useCallback(() => {
    setIsReaderLoading(false);
  }, []);

  useEffect(() => {
    if (isSuccess && book?.file) {
      setIsReaderLoading(true);
    }
  }, [book?.file, isSuccess]);

  return (
    <>
      <SEO title={book?.title ? `Reading ${book.title}` : 'Loading...'} />
      {(isLoading || isReaderLoading || !book?.file || !!book.releaseDate) && (
      <Application>
        <Content>
          <Container
            sx={{
              display: 'flex',
              height: '100%',
              width: '100%',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
            }}
          >
            {isLoading && !isReaderLoading && (
            <CircularProgress center />
            )}

            {isReaderLoading && !isLoading && (
            <>
              <Box marginBottom={1} width={400} maxWidth="100%"><LinearProgress /></Box>
              <Typography textAlign="center">Loading reader... This may take few minutes. Please be patient.</Typography>
            </>
            )}

            {!book?.file && isSuccess && !book.releaseDate && (
            <ReaderError />
            )}

            {isSuccess && !!book?.releaseDate && (
            <>
              <Typography>
                {book.title}
                {' '}
                will be available for reading on
                {' '}
                {toDate(book.releaseDate).format(FULL_DATE_UTC_FORMAT)}
              </Typography>

              <Link marginTop={1} href={`/${auth?.username}/library`}>Go to Library</Link>
            </>
            )}
          </Container>
        </Content>
      </Application>
      )}

      {!isLoading && !!book && book?.type === 'epub' && book.file && (
      <EpubReader
        file={book.file}
        title={book.title}
        slug={slug}
        onLoadSuccess={onLoadSuccess}
        isLoaded={!isLoading && !isReaderLoading}
      />
      )}
      {!isLoading && !!book && book?.type === 'pdf' && book.file && (
      <PDFReader
        file={book.file}
        title={book.title}
        onLoadSuccess={onLoadSuccess}
        isLoaded={!isLoading && !isReaderLoading}
      />
      )}
    </>
  );
};

export default Reader;
