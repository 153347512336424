import { Theme, useMediaQuery } from '@mui/material';
import { ReactElement, VFC } from 'react';

type DesktopProps = {
  children: ReactElement;
}

const Desktop: VFC<DesktopProps> = ({ children }) => {
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.up('md'));

  return matches ? children : null;
};

export default Desktop;
