import {
  FormControlLabel, FormHelperText, Switch as BaseSwitch, SwitchProps as BaseSwitchProps,
} from '@mui/material';
import { RefCallback, RefObject, VFC } from 'react';
import { BaseComponentFieldProps } from '../../types/app';

export type SwitchProps = BaseSwitchProps & BaseComponentFieldProps & {
  label: string;
      inputRef?: RefObject<unknown> | RefCallback<unknown>;
};

const Switch: VFC<SwitchProps> = ({
  disabled,
  label,
  helperText,
  error,
  inputRef,
  ...props
}) => (
  <>
    <FormControlLabel
      disabled={disabled}
      control={<BaseSwitch {...props} ref={inputRef} />}
      label={label}
    />
    <FormHelperText error={error}>{helperText}</FormHelperText>
  </>
);

export default Switch;
