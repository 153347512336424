import { Container, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { RouteComponentProps } from '@reach/router';
import { Delete } from 'mdi-material-ui';
import {
  FC, useCallback, useState,
} from 'react';
import useAppDispatch from '../../../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useDeleteUserBankMutation, useGetUserBanksQuery } from '../../../../../../plugins/gatsby-plugin-redux/store/api/flutterwave/bank.api';
import { addSnackbar } from '../../../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import dateWithoutYear from '../../../../../helpers/dateWithoutYear';
import { ErrorType, getErrorMessage } from '../../../../../helpers/handleSubmitAction';

import Button from '../../../../app/Button';
import LoadingButton from '../../../../app/LoadingButton';
import SEO from '../../../../app/SEO';
import AddBankModal from './AddBankModal';

const Bank: FC<RouteComponentProps> = () => {
  const [isAddBankModalOpen, setIsAddBankModalOpen] = useState(false);

  const { data: userBanks, isLoading, isFetching } = useGetUserBanksQuery();

  const [deleteUserBankAction, { isLoading: isDeletingUserBank }] = useDeleteUserBankMutation();

  const dispatch = useAppDispatch();

  const handleDelete = useCallback((id: string) => async () => {
    try {
      await deleteUserBankAction({ id }).unwrap();
    } catch (error) {
      const exception = error as ErrorType;
      if (exception.status !== 500) {
        dispatch(addSnackbar({ message: getErrorMessage(exception), variant: 'error' }));
      }
    }
  }, [deleteUserBankAction, dispatch]);

  return (
    <Container>
      <SEO title="Bank Settings" />
      <Button onClick={() => setIsAddBankModalOpen(true)} sx={{ marginY: 3 }}>Add Bank</Button>

      <AddBankModal isOpen={isAddBankModalOpen} onClose={() => setIsAddBankModalOpen(false)} />

      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={userBanks || []}
          loading={isLoading || isFetching}
          getRowId={(row) => row.id}
          columns={[
            {
              field: 'accountName',
              headerName: 'Account Name',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              flex: 1,
              minWidth: 100,
            },
            {
              field: 'accountNumber',
              headerName: 'Account Number',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
            },
            {
              field: 'bankName',
              headerName: 'Bank',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
            },
            {
              field: 'createdAt',
              headerName: 'Added on',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
              renderCell: ({
                row,
              }) => (
                dateWithoutYear(row.createdAt)
              ),
            },
            {
              field: 'actions',
              headerName: 'Actions',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
              renderCell: ({
                row,
              }) => (
                isDeletingUserBank
                  ? <LoadingButton variant="text" loading />
                  : (
                    <IconButton onClick={handleDelete(row.id)}>
                      <Delete />
                    </IconButton>
                  )
              ),
            },
          ]}
          pageSize={20}
          rowsPerPageOptions={[50, 100]}
          disableSelectionOnClick
          autoPageSize
        />
      </div>

    </Container>
  );
};

export default Bank;
