import {
  styled, Container,
} from '@mui/material';
import {
  createRef,
  FC, useCallback, useEffect, useMemo, useState,
} from 'react';
import BigNumber from 'bignumber.js';

import {
  Document as UnstyledDocument,
  Outline, Page as UnstyledPage,
  pdfjs, OutlineProps, PDFPageProxy,
  DocumentProps,
} from 'react-pdf';
import Application from '../../../app/Application';
import Content from '../../../app/Content';

import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Toolbar from '../Toolbar';
import ReaderError from '../ReaderError';

type PDFReaderProps = {
  file: string;
  title: string;
  onLoadSuccess: () => void;
  isLoaded: boolean;
}

type OutlineSchema = Parameters<Required<OutlineProps>['onLoadSuccess']>[0];
type PDFDocumentProxy = Parameters<Required<DocumentProps>['onLoadSuccess']>[0];

if (typeof window !== 'undefined' && 'Worker' in window) {
  pdfjs.GlobalWorkerOptions.workerPort = new Worker(
    new URL('pdfjs-dist/legacy/build/pdf.worker', import.meta.url),
  );
}

const Page = styled(UnstyledPage)(() => ({
  overflow: 'auto',
  height: 'inherit',
  '& .react-pdf__Page__svg': {
    margin: 'auto',
  },
}));

const Document = styled(UnstyledDocument)(() => ({
  height: 'inherit',
}));

const PDFReader: FC<PDFReaderProps> = ({
  file,
  title,
  isLoaded,
  onLoadSuccess,
}) => {
  const [pdf, setPdf] = useState<PDFDocumentProxy>();
  const [, setPage] = useState<PDFPageProxy>();
  const [, setOutline] = useState<OutlineSchema>();
  const [pageNumber, setPageNumber] = useState(1);
  const [scale, setScale] = useState(0.5);
  const [documentWidth, setDocumentWidth] = useState(0);
  const [documentHeight, setDocumentHeight] = useState(0);
  const [loadError, setLoadError] = useState<string|undefined>(undefined);

  const canvasRef = createRef<HTMLCanvasElement>();
  const documentRef = createRef<HTMLDivElement>();

  const numPages = useMemo(() => pdf?.numPages || 0, [pdf?.numPages]);

  const onDocumentLoadSuccess = useCallback((pdfDocument: PDFDocumentProxy) => {
    setPdf(pdfDocument);
    onLoadSuccess();
  }, [onLoadSuccess]);

  const onPageLoadSuccess = useCallback((pdfPage: PDFPageProxy) => {
    setPage(pdfPage);
    setPageNumber(pdfPage.pageNumber);
  }, []);

  const onOutlineLoadSuccess = useCallback((pdfOutline: OutlineSchema) => {
    setOutline(pdfOutline);
  }, []);

  const onItemClick = useCallback<Required<OutlineProps>['onItemClick']>(({
    pageNumber: itemPageNumber,
  }) => {
    setPageNumber(Number(itemPageNumber));
  }, []);

  const handlePrevious = useCallback(() => {
    setPageNumber(pageNumber - 1);
  }, [pageNumber]);

  const handleNext = useCallback(() => {
    setPageNumber(pageNumber + 1);
  }, [pageNumber]);

  const handleZoomOut = useCallback(() => {
    setScale((state) => Number(new BigNumber(state).minus(0.2).toFixed(2)));
  }, []);

  const handleZoomIn = useCallback(() => {
    setScale((state) => Number(new BigNumber(state).plus(0.2).toFixed(2)));
  }, []);

  const onDocumentLoadError = useCallback((error: Error) => {
    onLoadSuccess();
    setLoadError(error.message);
    // eslint-disable-next-line no-console
    console.error('pdf error', error);
  }, [onLoadSuccess]);

  useEffect(() => {
    if (documentRef.current) {
      const handleResize: ResizeObserverCallback = ([event]) => {
        const target = event.target?.querySelector('.react-pdf__Document')?.getBoundingClientRect();
        setDocumentWidth(
          Number(target?.width),
        );

        setDocumentHeight(
          Number(target?.height),
        );
      };

      new ResizeObserver(handleResize).observe(documentRef.current);
    }
  }, [documentRef]);

  useEffect(() => () => {
    void pdf?.destroy();
  }, [pdf]);

  return (
    <Application>
      <Content>
        <Container
          maxWidth="xl"
          ref={documentRef}
          sx={{
            height: 'calc(100vh - 70px)',
            width: '100vw',
            display: loadError ? 'flex' : undefined,
            justifyContent: loadError ? 'center' : undefined,
          }}
        >
          {!!loadError && <ReaderError />}

          {!loadError && (
          <>
            <Document
              file={file}
              onLoadSuccess={onDocumentLoadSuccess}
              onItemClick={onItemClick}
              onLoadError={onDocumentLoadError}
              onSourceError={onDocumentLoadError}
              noData={<ReaderError />}
              error={<ReaderError />}
              options={{
                cMapUrl: 'cmaps/',
                cMapPacked: true,
              }}
            >
              <Outline onItemClick={onItemClick} onLoadSuccess={onOutlineLoadSuccess} />
              <Page
                pageNumber={pageNumber}
                onLoadSuccess={onPageLoadSuccess}
                canvasRef={canvasRef}
                scale={scale}
                renderMode="svg"
                width={documentWidth}
                height={documentHeight}
                sx={{ maxWidth: '100%' }}
              />
            </Document>

            {isLoaded && (
            <Toolbar
              isNextBtnActive={pageNumber < numPages}
              isPrevBtnActive={pageNumber > 1}
              numPages={numPages}
              pageNumber={pageNumber}
              onZoomIn={handleZoomIn}
              onZoomOut={handleZoomOut}
              onNextPage={handleNext}
              onPreviousPage={handlePrevious}
              isZoomOutActive={scale === 0.4}
              isZoomInActive={scale === 1.5}
              title={title}
            />
            )}
          </>
          )}
        </Container>
      </Content>
    </Application>
  );
};

export default PDFReader;
