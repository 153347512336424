import { VFC } from 'react';
import Switch, { SwitchProps } from '../components/app/Switch';
import useFormController from '../hooks/useFormController';
import { FieldProps } from './FieldControl';

type SwitchFieldProps = FieldProps & SwitchProps;

const SwitchField: VFC<SwitchFieldProps> = (props) => {
  const { field: { ref, ...field }, hasError } = useFormController<boolean>({
    name: props.name,
    defaultValue: props.value as boolean,
    helperText: props.helperText,
    rules: props.rules,
    onChange: props.onChange,
  });

  return (
    <Switch
      {...props}
      {...field}
      inputRef={ref}
      checked={!!field.value}
      error={hasError}
      onChange={(_, value) => {
        field.onChange(value);
        props.onChange?.(value);
      }}
    />
  );
};

export default SwitchField;
