import { FC } from 'react';
import Select, { SelectProps } from '../components/app/Select';
import FieldControl, { FieldProps } from './FieldControl';

type SelectFieldProps = FieldProps & SelectProps;

const SelectField: FC<SelectFieldProps> = (props) => (
  <FieldControl component={Select} {...props} />
);

export default SelectField;
