import {
  Container, Stack, Typography,
} from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { FC } from 'react';
import ComingSoon from '../../../icons/ComingSoon';

type IntegrationsProps = RouteComponentProps;

const Integrations: FC<IntegrationsProps> = () => (
  <Container sx={{ height: '100%', display: 'flex', alignItems: 'center' }}>
    <Stack justifyContent="center" textAlign="center" alignItems="center">
      <ComingSoon sx={{ margin: 'auto', marginBottom: 1 }} />
      <Typography variant="h4" fontWeight="600" gutterBottom>Coming soon!</Typography>
      <Typography>
        Integrations allow you to connect your Litireso account with third-party tools
        like Google Analytics, Facebook Pixel, Slack etc to track publications activities.
      </Typography>
    </Stack>
  </Container>
);

export default Integrations;
