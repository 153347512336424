import { FC } from 'react';
import InputNumber, { InputNumberProps } from '../components/app/InputNumber';
import FieldControl, { FieldProps } from './FieldControl';

type InputNumberFieldProps = FieldProps & InputNumberProps;

const InputNumberField: FC<InputNumberFieldProps> = (props) => (
  <FieldControl component={InputNumber} {...props} />
);

export default InputNumberField;
