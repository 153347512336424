import { Delete } from '@mui/icons-material';
import {
  alpha, DialogActions,
  DialogContent, DialogTitle,
  IconButton, Link, Theme,
  Toolbar, Tooltip, Typography,
} from '@mui/material';
import { GridSelectionModel, DataGrid, GridRenderCellParams } from '@mui/x-data-grid';
import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import {
  useCallback, useMemo, useState, VFC,
} from 'react';
import {
  useCreatePublicationMutation,
  useGetPublicationsQuery,
} from '../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import Form from '../../../../field/Form';
import InputField from '../../../../field/InputField';
import SelectField from '../../../../field/SelectField';
import dateWithoutYear from '../../../../helpers/dateWithoutYear';
import handleSubmitAction from '../../../../helpers/handleSubmitAction';
import { isRequired } from '../../../../helpers/validators';
import { SubmitHandler } from '../../../../types/app';
import { CreatePublicationFormData } from '../../../../types/form';
import Button from '../../../app/Button';
import Container from '../../../app/Container';
import Dialog from '../../../app/Dialog';
import ErrorAlert from '../../../app/ErrorAlert';
import LoadingButton from '../../../app/LoadingButton';
import Spacer from '../../../app/Spacer';

const Publications: VFC<RouteComponentProps> = () => {
  const [selectedRecipients, setSelectedRecipients] = useState<GridSelectionModel>([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);

  const { data: books = [], isLoading } = useGetPublicationsQuery();

  const numSelected = useMemo(() => selectedRecipients.length, [selectedRecipients]);

  const [
    createPublicationAction,
    { isLoading: isCreatingPublication },
  ] = useCreatePublicationMutation();

  const bgColor = useCallback((theme: Theme) => (
    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
  ), []);

  const handleCreatePublication = useCallback<SubmitHandler<CreatePublicationFormData>>(async (
    values,
  ) => {
    const result = await createPublicationAction(values).unwrap();
    void navigate(`${result.slug}`);
  }, [createPublicationAction]);

  return (
    <Container maxWidth="md" sx={{ marginLeft: 'unset', paddingBottom: 5, paddingTop: 5 }}>
      <Dialog
        open={openCreateModal}
        onClose={() => setOpenCreateModal(false)}
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle>Create a new publication</DialogTitle>
        <Form<CreatePublicationFormData>
          initialValues={{ title: '', type: 'ebook' }}
          onSubmit={handleSubmitAction(handleCreatePublication)}
        >
          {(config) => (
            <form onSubmit={config.handleSubmit}>
              <DialogContent>
                <ErrorAlert message={config.errors._server?.message} />
                <InputField label="Title" name="title" fullWidth rules={[isRequired]} />
                <SelectField
                  name="type"
                  label="Publication type"
                  options={[{ text: 'Ebook', value: 'ebook' }]}
                  fullWidth
                  rules={[isRequired]}
                />

              </DialogContent>
              <DialogActions>
                <LoadingButton type="submit" variant="text" loading={isCreatingPublication}>Create</LoadingButton>
              </DialogActions>
            </form>
          )}
        </Form>
      </Dialog>
      <Typography variant="h4">Publications</Typography>
      <Typography>Manage all published books here</Typography>

      <Toolbar
        sx={{
          paddingLeft: { xs: numSelected > 0 ? 2 : 0 },
          paddingRight: { xs: numSelected > 0 ? 1 : 0 },
          ...(numSelected > 0 && { bgcolor: bgColor }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}
            {' '}
            selected
          </Typography>
        ) : (
          <>
            <Spacer />
            <Button
              disableElevation
              onClick={() => setOpenCreateModal(true)}
            >
              Add publication
            </Button>
          </>
        )}
        {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton>
            <Delete />
          </IconButton>
        </Tooltip>
        )}
      </Toolbar>

      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={books}
          loading={isLoading}
          getRowId={(row) => row.slug}
          columns={[
            {
              field: 'title',
              headerName: 'Title',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              flex: 1,
              minWidth: 100,
              renderCell: ({ row }: GridRenderCellParams<string, typeof books[0], any>) => (
                <Link href={`${row.slug}`}>{row.title}</Link>
              ),
            },
            {
              field: 'rating',
              headerName: 'Average Rating',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
            },
            {
              field: 'updatedAt',
              headerName: 'Last Updated',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
              renderCell: ({
                row,
              }) => (
                dateWithoutYear(row.updatedAt as string)
              ),
            },
          ]}
          pageSize={20}
          rowsPerPageOptions={[50, 100]}
          checkboxSelection
          onSelectionModelChange={setSelectedRecipients}
          selectionModel={selectedRecipients}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          autoPageSize
        />
      </div>
    </Container>
  );
};

export default Publications;
