import { Alert } from '@mui/material';
import { Box } from '@mui/system';
import { RouteComponentProps } from '@reach/router';
import dayjs from 'dayjs';
import { graphql, StaticQuery } from 'gatsby';
import {
  FC,
  useCallback, useMemo,
} from 'react';
import useAppDispatch from '../../../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useSaveBasicInfoMutation } from '../../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import { addSnackbar } from '../../../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import AutocompleteField from '../../../../../field/AutocompleteField';
import DatePickerField from '../../../../../field/DatePickerField';
import Form from '../../../../../field/Form';
import InputField from '../../../../../field/InputField';
import SelectField from '../../../../../field/SelectField';
import SwitchField from '../../../../../field/SwitchField';
import handleSubmitAction from '../../../../../helpers/handleSubmitAction';
import { isRequired, isNullable, isISBN } from '../../../../../helpers/validators';
import { SubmitHandler } from '../../../../../types/app';
import { EditBasicInfoFormData } from '../../../../../types/form';
import { BookSchema } from '../../../../../types/schema';
import ErrorAlert from '../../../../app/ErrorAlert';
import LoadingButton from '../../../../app/LoadingButton';

type BookInfoProps = {
  book: BookSchema;
  slug: string;
}

const query = graphql`
 query LanguagesGenres {
    allLanguage {
      nodes {
        id
        name
      }
    }
    allGenre {
    nodes {
    id
    title
    }}
  }`;

const BasicInfo: FC<RouteComponentProps & BookInfoProps> = ({
  book,
  slug,
}) => {
  const [saveBasicInfo, { isLoading: isSaving }] = useSaveBasicInfoMutation();
  const dispatch = useAppDispatch();

  const isPrint = useMemo(() => book.type === 'print', [book.type]);

  const handleSubmit = useCallback<SubmitHandler<EditBasicInfoFormData>>(async ({
    ISBN,
    publishedDate,
    genreIds,
    ...values
  }) => {
    await saveBasicInfo({
      ...values,
      slug,
      ISBN: ISBN || undefined,
      publishedDate: dayjs(publishedDate).format('YYYY'),
      genreIds: (genreIds as unknown as Record<string, string>[]).map((item) => item.id),
    }).unwrap();
    dispatch(addSnackbar({ message: 'Saved successfully', variant: 'success' }));
  }, [dispatch, saveBasicInfo, slug]);

  return (
    <Box>
      <Alert severity="info" sx={{ marginBottom: 2 }}>All fields are required except where specified otherwise</Alert>
      <Form<EditBasicInfoFormData>
        onSubmit={handleSubmitAction(handleSubmit)}
        initialValues={{
          description: book?.description || '',
          title: book?.title || '',
          languageId: book?.language?.id || '',
          ISBN: book?.ISBN || '',
          quantity: book?.quantity || 0,
          isAdultType: book?.isAdultType,
          publishedDate: book?.publishedDate || '',
          publisherName: book?.publisherName || '',
          genreIds: book?.genres as unknown as string[] || [],
        }}
      >
        {(config) => (
          <form onSubmit={config.handleSubmit}>
            <ErrorAlert message={config.errors._server?.message} />
            <InputField fullWidth name="title" label="Title" rules={[isRequired]} />
            <InputField fullWidth name="description" label="Blurb" multiline minRows={4} rules={[isRequired]} />
            {isPrint && (
            <InputField
              fullWidth
              name="quantity"
              label="Quantity"
              placeholder="How many quantities are you selling?"
              rules={isPrint ? [isRequired] : [isNullable]}
            />
            )}
            <StaticQuery<Queries.LanguagesGenresQuery> query={query}>
              {({ allLanguage, allGenre }) => (
                <>
                  <SelectField
                    name="languageId"
                    options={allLanguage.nodes.map((item) => ({ text: item.name, value: item.id }))}
                    fullWidth
                    label="Language"
                    rules={[isRequired]}
                  />
                  <AutocompleteField<typeof allGenre.nodes[0], true>
                    name="genreIds"
                    options={allGenre.nodes}
                    filterSelectedOptions
                    getOptionLabel={(item) => item.title}
                    isOptionEqualToValue={(option, value) => option.title === value.title}
                    fullWidth
                    label="Genre"
                    multiple
                    rules={[isRequired]}
                  />
                </>
              )}
            </StaticQuery>
            <InputField
              fullWidth
              name="ISBN"
              label={`ISBN${!isPrint ? ' (optional)' : ''}`}
              rules={isPrint ? [isRequired, isISBN] : [isNullable, isISBN]}
            />
            <InputField
              fullWidth
              name="publisherName"
              label={`Publisher Name${!isPrint ? ' (optional)' : ''}`}
              rules={isPrint ? [isRequired] : []}
            />
            <DatePickerField
              InputProps={{ fullWidth: true }}
              name="publishedDate"
              label="Year Published"
              inputFormat="YYYY"
              views={['year']}
              maxDate={dayjs()}
              rules={[isRequired]}
            />
            <Box marginTop={3}>
              <SwitchField
                name="isAdultType"
                label="Contains Adult content and use of strong language"
                rules={[isNullable]}
              />
            </Box>

            <LoadingButton type="submit" loading={isSaving}>Save</LoadingButton>
          </form>
        )}
      </Form>
    </Box>
  );
};

export default BasicInfo;
