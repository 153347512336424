import { api } from '..';
import { CurrencyCodeType, ServerResponse } from '../../../../../src/types/app';
import { WalletTransactionSchema } from '../../../../../src/types/schema';
import { SummarySchema } from '../../../../../src/types/schema/summary.schema';

type SummaryQuery = { slug: string; currencyCode: CurrencyCodeType; };
type SummaryReturnType = { summary: SummarySchema; data: WalletTransactionSchema[]; };

const summaryApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getSummary: builder.query<SummaryReturnType, SummaryQuery>({
      providesTags: ['Earnings'],
      query: (params) => ({
        url: 'dashboard/statistics/summaries',
        method: 'GET',
        params,
      }),
      transformResponse: (
        response: ServerResponse<WalletTransactionSchema[]> & SummarySchema,
      ) => ({
        data: response.data,
        summary: {
          balance: response.balance || 0,
          currencyCode: response.currencyCode,
          amountEarned: response.amountEarned,
          purchaseCount: response.purchaseCount,
          totalPayouts: response.totalPayouts,
          graph: response.graph,
        },
      }),
    }),
  }),
});

export const {
  useGetSummaryQuery,
} = summaryApi;
