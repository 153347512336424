import { api } from '..';
import { ServerResponse } from '../../../../../src/types/app';
import { AddBankFormData } from '../../../../../src/types/form';
import { BankBranchSchema, BankSchema, UserBankSchema } from '../../../../../src/types/schema/bank.schema';

const bankApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getBanksByCountries: builder.query<BankSchema[], string>({
      query: (countryCode) => ({ url: '/dashboard/banks', params: { countryCode } }),
    }),
    getBankBranches: builder.query<BankBranchSchema[], string>({
      query: (id) => ({ url: `/dashboard/banks/${id}/branches` }),
      transformResponse: (response: Record<string, string>[]) => (
        response.map((item) => ({
          code: item.branchCode,
          name: item.branchName,
        }))
      ),
    }),
    addBank: builder.mutation<UserBankSchema, AddBankFormData >({
      query: (formData) => ({ url: '/dashboard/wallets/banks', body: formData, method: 'POST' }),
      onQueryStarted: async (_, { queryFulfilled, dispatch }) => {
        const result = await queryFulfilled;

        dispatch(
          bankApi.util.updateQueryData('getUserBanks', undefined, (draft) => {
            draft.unshift(result.data);
          }),
        );
      },
      transformResponse: (response: ServerResponse<UserBankSchema>) => response.data,
    }),
    getUserBanks: builder.query<UserBankSchema[], void>({
      query: () => ({ url: '/dashboard/wallets/banks' }),
      transformResponse: (response: ServerResponse<UserBankSchema[]>) => response.data,
    }),
    deleteUserBank: builder.mutation<void, {id:string} >({
      query: ({ id }) => ({ url: `/dashboard/wallets/banks/${id}`, method: 'DELETE' }),
      onQueryStarted: async ({ id }, { queryFulfilled, dispatch }) => {
        const patches = dispatch(
          bankApi.util.updateQueryData('getUserBanks', undefined, (draft) => {
            const index = draft.findIndex((item) => item.id === id);
            if (index !== -1) {
              draft.splice(index, 1);
            }
          }),
        );

        await queryFulfilled.catch(patches.undo);
      },
    }),
  }),
});

export const {
  useLazyGetBanksByCountriesQuery,
  useLazyGetBankBranchesQuery,
  useAddBankMutation,
  useGetUserBanksQuery,
  useDeleteUserBankMutation,
} = bankApi;
