import { Toc } from '@mui/icons-material';
import {
  colors, Stack, ButtonGroup, IconButton, Typography, Box, Menu,
} from '@mui/material';
import {
  ArrowUp, ArrowDown, MagnifyMinusOutline, MagnifyPlusOutline,
} from 'mdi-material-ui';
import {
  FC, useCallback,
  useMemo, useState,
} from 'react';
import Spacer from '../../app/Spacer';
import ChapterList, { TocItem } from './ChapterList';

type ToolbarProps = {
  onPreviousPage?: () => void
  onNextPage?: () => void
  onZoomIn?: () => void
  onZoomOut?: () => void
  numPages?: number;
  pageNumber?: number;
  title?: string;
  toc?: TocItem[];
  defaultSelectedToc?: string;
  isPrevBtnActive?: boolean;
  isNextBtnActive?: boolean;
  isZoomOutActive?: boolean;
  isZoomInActive?: boolean;
}

const Toolbar: FC<ToolbarProps> = ({
  onPreviousPage,
  onNextPage,
  numPages,
  onZoomIn,
  onZoomOut,
  pageNumber,
  isZoomInActive,
  isZoomOutActive,
  title,
  toc = [],
  defaultSelectedToc,
  isPrevBtnActive,
  isNextBtnActive,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const open = useMemo(() => Boolean(anchorEl), [anchorEl]);

  const renderToc = useCallback(() => (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={() => setAnchorEl(null)}
      PaperProps={{
        style: {
          maxHeight: 600,
          width: 400,
          maxWidth: '100%',
        },
      }}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      <ChapterList items={toc} defaultSelected={defaultSelectedToc} />
    </Menu>
  ), [anchorEl, defaultSelectedToc, open, toc]);

  return (
    <Box position="fixed" bottom={10} width={600} maxWidth="100%" margin="auto" left={0} right={0}>
      <Box borderRadius={4} sx={{ backgroundColor: colors.blueGrey['50'] }} paddingY={1} paddingX={2} margin="auto" width="calc(100% - 20px)">
        <Stack flexDirection="row" alignItems="center" justifyContent="center" flexWrap="wrap">
          <ButtonGroup variant="outlined" size="small">
            <IconButton disabled={!isPrevBtnActive} onClick={onPreviousPage}><ArrowUp fontSize="small" /></IconButton>
            <IconButton disabled={!isNextBtnActive} onClick={onNextPage}><ArrowDown fontSize="small" /></IconButton>
          </ButtonGroup>
          <Spacer />
          <Typography variant="h6" fontWeight="600" noWrap>{title}</Typography>
          <Spacer />
          <Stack flexDirection="row" columnGap={1} alignItems="center">
            {pageNumber && numPages && <Typography variant="subtitle1">{`${pageNumber}/${numPages}`}</Typography>}
            <Spacer />
            {!!toc.length && (
            <>
              {renderToc()}
              <IconButton onClick={(event) => setAnchorEl(event.currentTarget)}><Toc /></IconButton>
              <Spacer />
            </>
            )}
            {(onZoomOut || onZoomIn) && (
            <ButtonGroup variant="outlined" size="small">
              <IconButton disabled={isZoomOutActive} onClick={onZoomOut}><MagnifyMinusOutline fontSize="small" /></IconButton>
              <IconButton disabled={isZoomInActive} onClick={onZoomIn}><MagnifyPlusOutline fontSize="small" /></IconButton>
            </ButtonGroup>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );
};

export default Toolbar;
