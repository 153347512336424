import { FC } from 'react';
import { RouteComponentProps } from '@reach/router';
import {
  Alert, Box, InputLabel,
} from '@mui/material';
import { BookSchema } from '../../../../../types/schema';
import UploadFile from '../../../../app/UploadFile';

type MediaTabProps = {
  book: BookSchema;
  slug: string;
}

const MediaTab: FC<RouteComponentProps & MediaTabProps> = ({ book, slug }) => (
  <Box>
    <InputLabel sx={{ marginBottom: 3, fontWeight: '600' }}>Cover Image</InputLabel>
    <Alert
      severity="info"
      sx={{ marginBottom: 1, whiteSpace: 'pre-wrap', lineHeight: '100%' }}
    >
      {`We support images up to 1MB in size and they must be in either jpg or png format.
          \nRecommended size 800 pixels x 1200 pixels.`}
    </Alert>
    <UploadFile
      acceptedFileTypes={['image/jpg', 'image/jpeg', 'image/png']}
      defaultValue={book.cover}
      params={{ slug, type: 'cover' }}
      maxFileSize="1MB"
      imageValidateSizeMaxHeight={1200}
      imageValidateSizeMinHeight={320}
      imageValidateSizeMaxWidth={800}
      imageValidateSizeMinWidth={200}
    />

    {book.type === 'ebook' && (
      <Box>
        <InputLabel sx={{ marginBottom: 3, fontWeight: '600' }}>File</InputLabel>

        <Alert severity="info" sx={{ marginBottom: 1, whiteSpace: 'pre-wrap', lineHeight: '100%' }}>
          {`We support both Epub and PDF files up to 50MB in size.
          \nEnsure your file is in either of these formats and they are not just renamed.
          \nWe recommend PDF for magazines or comics and EPUB for stories or other book format to provide the best reading experience for your readers.`}
        </Alert>

        <Alert severity="warning" sx={{ marginBottom: 1 }}>
          We have added extra security layer to prevent people
          from downloading and illegally distributing your publication.
        </Alert>

        <UploadFile
          acceptedFileTypes={['application/epub+zip', 'application/pdf']}
          defaultValue={book.file}
          params={{ slug, type: 'book' }}
          maxFileSize="50MB"
          previewUrl={`/app/reader/${book.slug}`}
        />
      </Box>
    )}
  </Box>
);

export default MediaTab;
