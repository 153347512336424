import { ReaderSchema } from '../../../../src/types/schema';
import { api } from '.';

const readerApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getReader: builder.query<ReaderSchema, string>({
      query: (slug) => `dashboard/reader/${slug}`,
    }),
  }),
});

export const {
  useGetReaderQuery,
} = readerApi;
