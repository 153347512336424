import {
  AutocompleteProps as BaseAutocompleteProps,
  TextField, Autocomplete as BaseAutocomplete, AutocompleteRenderInputParams,
} from '@mui/material';
import { RefCallback, RefObject } from 'react';
import FieldLabel from '../../field/FieldLabel';
import { BaseComponentFieldProps } from '../../types/app';

export type AutocompleteProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = Omit<BaseAutocompleteProps<T, Multiple, DisableClearable, FreeSolo>, 'renderInput' | 'onChange'> & BaseComponentFieldProps & {
    myProp?: string;
    inputRef?: RefObject<unknown> | RefCallback<unknown>;
    onChange?: (value: unknown) => void;
}

const Autocomplete = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
  >({
    label,
    id,
    error,
    helperText,
    name,
    inputRef,
    ...props
  }: AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>) => (
    <>
      <FieldLabel
        content={label}
        htmlFor={id}
        hasError={!!error}
        disabled={props.disabled}
      />

      <BaseAutocomplete
        {...props}
        onChange={(_, value) => props.onChange?.(value)}
        ref={inputRef}
        renderInput={(params: AutocompleteRenderInputParams) => (
          <TextField
            {...params}
            name={name}
            error={error}
            id={id}
            size="small"
            helperText={helperText}
            inputProps={{
              ...params.inputProps,
              autoComplete: 'new-password',
            }}
          />
        )}
      />
    </>
  );

export default Autocomplete;
