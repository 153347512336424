import { Delete } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Stack, Toolbar,
  Typography, Tooltip, IconButton,
  Theme, DialogContent, DialogTitle,
  DialogActions, Alert, Button,
} from '@mui/material';
import { alpha } from '@mui/system';
import { GridSelectionModel, DataGrid } from '@mui/x-data-grid';
import { RouteComponentProps } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import {
  useState, useMemo, useCallback, VFC,
} from 'react';
import useAppDispatch from '../../../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useAddCollaboratorMutation, useDeleteCollaboratorMutation } from '../../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import { addSnackbar } from '../../../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import Form from '../../../../../field/Form';
import InputField from '../../../../../field/InputField';
import SelectField from '../../../../../field/SelectField';
import handleSubmitAction, { ErrorType, getErrorMessage } from '../../../../../helpers/handleSubmitAction';
import { isEmail, isRequired } from '../../../../../helpers/validators';
import { SubmitHandler } from '../../../../../types/app';
import { AddCollaboratorFormData } from '../../../../../types/form';
import { BookSchema } from '../../../../../types/schema';
import Dialog from '../../../../app/Dialog';
import ErrorAlert from '../../../../app/ErrorAlert';
import HtmlForm from '../../../../app/HtmlForm';
import Spacer from '../../../../app/Spacer';

type CollaboratorsTabProps = {
  book: BookSchema;
  isLoading: boolean;
  hasCollaborators: boolean;
  slug: string;
}

const query = graphql`
  query CollaboratorTypes {
    allCollaboratorType {
      nodes {
        id
        name
      }
    }
  }
`;

const CollaboratorsTab: VFC<RouteComponentProps & CollaboratorsTabProps> = ({
  book,
  isLoading,
  hasCollaborators,
  slug,
}) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedCollaborators, setSelectedCollaborators] = useState<GridSelectionModel>([]);

  const [addCollaboratorAction, { isLoading: isAddingCollaborator }] = useAddCollaboratorMutation();
  const [
    deleteCollaboratorsAction,
  ] = useDeleteCollaboratorMutation();

  const numSelected = useMemo(() => selectedCollaborators.length, [selectedCollaborators]);

  const dispatch = useAppDispatch();

  const removeCollaborators = useCallback(async () => {
    try {
      await deleteCollaboratorsAction({
        slug,
        collaboratorIds: selectedCollaborators as string[],
      }).unwrap();
    } catch (error) {
      const exception = error as ErrorType;
      dispatch(addSnackbar({
        message: getErrorMessage(exception),
        variant: 'error',
      }));
    }
  }, [deleteCollaboratorsAction, dispatch, selectedCollaborators, slug]);

  const onSubmit = useCallback<SubmitHandler<AddCollaboratorFormData>>(async (values, helpers) => {
    await addCollaboratorAction({ ...values, slug }).unwrap();
    setIsAddDialogOpen(false);
    helpers.resetForm();
  }, [addCollaboratorAction, slug]);

  const bgColor = useCallback((theme: Theme) => (
    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity)
  ), []);

  return (
    <>
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        persistent={isAddingCollaborator}
      >
        <DialogTitle>Add collaborator</DialogTitle>
        <Form<AddCollaboratorFormData>
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            type: 'author',
          }}
          onSubmit={handleSubmitAction(onSubmit)}
        >
          {(config) => (
            <HtmlForm onSubmit={config.handleSubmit}>
              <DialogContent>
                <ErrorAlert message={config.errors._server?.message} />
                <Stack flexDirection={{ xs: 'column', md: 'row' }} gap={1} marginBottom={2}>
                  <InputField name="firstName" fullWidth noMargingBottom label="First Name" rules={[isRequired]} />
                  <InputField name="lastName" fullWidth noMargingBottom label="Last Name" rules={[isRequired]} />
                </Stack>
                <InputField name="email" fullWidth label="Email address (optional)" rules={[isRequired, isEmail]} />
                <StaticQuery<Queries.CollaboratorTypesQuery> query={query}>
                  {({ allCollaboratorType }) => (
                    <SelectField
                      name="type"
                      fullWidth
                      label="Role"
                      options={allCollaboratorType.nodes.map((item) => ({
                        text: item.name,
                        value: item.id,
                      }))}
                      rules={[isRequired]}
                    />
                  )}
                </StaticQuery>
              </DialogContent>
              <DialogActions>
                <LoadingButton variant="text" type="submit" loading={isAddingCollaborator}>Submit</LoadingButton>
              </DialogActions>
            </HtmlForm>
          )}
        </Form>
      </Dialog>

      {!hasCollaborators && (
      <Alert severity="info" sx={{ marginBottom: 3 }}>
        Please add a collaborator with an author role so you can publish this publication.
      </Alert>
      )}

      <Toolbar
        sx={{
          paddingLeft: { xs: numSelected > 0 ? 2 : 0 },
          paddingRight: { xs: numSelected > 0 ? 1 : 0 },
          ...(numSelected > 0 && { bgcolor: bgColor }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected}
            {' '}
            selected
          </Typography>
        ) : (
          <>
            <Spacer />
            <Button
              disableElevation
              onClick={() => setIsAddDialogOpen(true)}
            >
              Add collaborator
            </Button>
          </>
        )}
        {numSelected > 0 && (
        <Tooltip title="Delete">
          <IconButton onClick={removeCollaborators}>
            <Delete />
          </IconButton>
        </Tooltip>
        )}
      </Toolbar>

      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={book.collaborators || []}
          loading={isLoading}
          columns={[
            {
              field: 'name',
              headerName: 'Name',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              flex: 1,
              minWidth: 100,
            },
            {
              field: 'type',
              headerName: 'Type',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              flex: 1,
              minWidth: 100,
            },
            {
              field: 'email',
              headerName: 'Email Address',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
              minWidth: 200,
              flex: 1,
            },
          ]}
          pageSize={20}
          checkboxSelection
          onSelectionModelChange={setSelectedCollaborators}
          selectionModel={selectedCollaborators}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          autoPageSize
        />
      </div>
    </>
  );
};

export default CollaboratorsTab;
