import {
  FC, lazy, ReactNode, Suspense, useMemo,
} from 'react';

import SEO from '../../components/app/SEO';

const BaseDashboardLayout = lazy(() => import(/* webpackChunkName: "BaseDashboardLayout" */'./BaseDashboardLayout'));

const DashboardLayout: FC<{ children: ReactNode; }> = ({ children }) => {
  // if (/^\/app\/dashboard\/publications\/(.)+/.test(location.pathname)) {
  //   return PublicationLayout;
  // }
  const LayoutComponent = useMemo(() => BaseDashboardLayout, []);

  return (
    <>
      <SEO title="Connecting People to African content" />
      {LayoutComponent
        ? (
          <Suspense fallback={null}>
            <LayoutComponent>{children}</LayoutComponent>
          </Suspense>
        )
        : children }
    </>
  );
};

export default DashboardLayout;
