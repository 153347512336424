import {
  FormHelperText,
  MenuItem,
  Select as BaseSelect,
  SelectProps as BaseSelectProps,
} from '@mui/material';
import { FC } from 'react';
import FieldLabel from '../../field/FieldLabel';
import { BaseComponentFieldProps } from '../../types/app';

export type SelectProps = Exclude<BaseSelectProps, 'children' | 'label' | 'onChange'> & BaseComponentFieldProps & {
  options: { text: string; value: string; }[];
  onChange?: (value: string) => void;
}

const Select: FC<SelectProps> = ({
  options,
  label,
  helperText,
  ...props
}) => (
  <>
    <FieldLabel
      content={label}
      htmlFor={props.id}
      hasError={props.error}
      disabled={props.disabled}
    />

    <BaseSelect
      size="small"
      displayEmpty
      {...props}
      onChange={(event) => props.onChange?.(event.target.value as string)}
    >
      {options.map((option) => (
        <MenuItem key={option.text} disabled={option.value === ''} value={option.value}>
          {option.text}
        </MenuItem>
      ))}
    </BaseSelect>

    {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>}
  </>
);

export default Select;
