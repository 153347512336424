import {
  IconButton, InputAdornment, TextField as BaseTextField, TextFieldProps as BaseTextFieldProps,
} from '@mui/material';
import { Eye, EyeOff } from 'mdi-material-ui';
import { useCallback, useState } from 'react';
import FieldLabel from '../../field/FieldLabel';
import { BaseComponentFieldProps } from '../../types/app';

export type InputPasswordProps = BaseTextFieldProps & BaseComponentFieldProps;

const InputPassword = ({
  label,
  id,
  error,
  ...props
}: InputPasswordProps) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = useCallback(() => {
    setIsVisible(!isVisible);
  }, [isVisible]);

  return (
    <>
      <FieldLabel
        content={label}
        htmlFor={id}
        hasError={error}
        disabled={props.disabled}
      />

      <BaseTextField
        variant="outlined"
        size="small"
        {...props}
        error={error}
        id={id}
        InputProps={{
          ...props.InputProps,
          type: isVisible ? 'text' : 'password',
          endAdornment: (
            <InputAdornment position="end">
              <IconButton size="small" onClick={toggleVisibility}>
                {isVisible ? <EyeOff /> : <Eye />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </>
  );
};

export default InputPassword;
