import { Stack, Typography } from '@mui/material';
import { FC } from 'react';
import Void from '../../icons/Void';

const ReaderError: FC = () => (
  <Stack alignItems="center" justifyContent="center">
    <Void />
    <Typography marginTop={2} gutterBottom>
      We are unable to load this ebook.
      Please refresh your browser or contact
      {' '}
      <a href="mailto:support@litireso.africa">support@litireso.africa</a>
      {' '}
      if the issue persist.
    </Typography>
  </Stack>
);

export default ReaderError;
