import { VFC } from 'react';
import InputPassword, { InputPasswordProps } from '../components/app/InputPassword';
import FieldControl, { FieldProps } from './FieldControl';

type InputPasswordFieldProps = FieldProps & InputPasswordProps;

const InputPasswordField: VFC<InputPasswordFieldProps> = (props) => (
  <FieldControl component={InputPassword} {...props} />
);

export default InputPasswordField;
