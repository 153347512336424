import {
  FormLabel,
  Grid,
  List, ListItem, ListItemButton, Typography,
} from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import {
  useCallback, useEffect, useState, VFC,
} from 'react';
import Radio from '@mui/material/Radio';
import FormControlLabel from '@mui/material/FormControlLabel';
import { navigate, graphql, StaticQuery } from 'gatsby';
import { useCreateWalletMutation } from '../../../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import Button from '../../app/Button';
import Container from '../../app/Container';
import AlertDialog from '../../app/AlertDialog';
import RadioGroup from '../../app/RadioGroup';
import useIsLoggedIn from '../../../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';
import useAuth from '../../../../plugins/gatsby-plugin-session-check/hooks/useAuth';
import { ErrorType, getErrorMessage } from '../../../helpers/handleSubmitAction';
import useAppDispatch from '../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { addSnackbar } from '../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import useCurrencyChooser from '../../../../plugins/gatsby-plugin-currency-chooser/src/useCurrencyChooser';
import { CurrencyCodeType } from '../../../types/app';

const query = graphql`
  query OnboardingCurrency {
    allCurrency {
      nodes {
        code
        symbol
      }
    }
  }
`;

const CreateWalletOnboarding: VFC<RouteComponentProps> = () => {
  const [isAlertOpen, setIsAlertOpen] = useState(false);
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('');
  const [createWalletAction, { isLoading: isCreatingWallet }] = useCreateWalletMutation();
  const isLoggedIn = useIsLoggedIn();
  const auth = useAuth();

  const dispatch = useAppDispatch();
  const { setCurrencyCode } = useCurrencyChooser();

  useEffect(() => {
    if (isLoggedIn && auth?.hasWallet) {
      void navigate('/');
    }
  }, [isLoggedIn, auth?.hasWallet]);

  const handleSubmit = useCallback(async () => {
    try {
      await createWalletAction(selectedCurrencyCode).unwrap();
      setCurrencyCode(selectedCurrencyCode as unknown as CurrencyCodeType);
    } catch (error) {
      setIsAlertOpen(false);

      const exception = error as ErrorType;
      if (exception.status === 500) return;

      dispatch(addSnackbar({ message: getErrorMessage(exception), variant: 'error' }));
    }
  }, [createWalletAction, selectedCurrencyCode, setCurrencyCode, dispatch]);

  return (
    <Container maxWidth="md">
      <Typography variant="h4" component="h1" fontWeight="600" textAlign="center" gutterBottom marginTop={5}>Create your Litireso Wallet</Typography>
      <Typography textAlign="center" marginBottom={5} width={600} maxWidth="100%" marginLeft="auto" marginRight="auto">
        Your free Litireso Wallet allows you to purchase books seamlessly using a single wallet.
      </Typography>

      <FormLabel sx={{ marginBottom: 3, display: 'block' }}>Please choose your wallet currency</FormLabel>
      <List>
        <RadioGroup name="code" value={selectedCurrencyCode} onChange={(_, value) => setSelectedCurrencyCode(value)}>
          <Grid container>
            <StaticQuery<Queries.OnboardingCurrencyQuery> query={query}>
              {({ allCurrency }) => allCurrency.nodes.map(({ code, symbol }) => (
                <Grid item md={4} xs={12} key={code}>
                  <ListItem key={code} disablePadding>
                    <ListItemButton>
                      <FormControlLabel
                        sx={{ width: '100%' }}
                        value={code}
                        control={<Radio />}
                        label={`${code} (${symbol})`}
                      />
                    </ListItemButton>
                  </ListItem>
                </Grid>
              ))}
            </StaticQuery>
          </Grid>
        </RadioGroup>
      </List>

      <Button
        fullWidth
        sx={{ marginTop: 5 }}
        onClick={() => setIsAlertOpen(true)}
        disabled={!selectedCurrencyCode}
      >
        Create wallet
      </Button>

      <AlertDialog
        persistent={isCreatingWallet}
        isOpen={isAlertOpen}
        onClose={() => setIsAlertOpen(false)}
        btnOk={{ label: 'I understand', onSubmit: handleSubmit, isLoading: isCreatingWallet }}
        title={`Create ${selectedCurrencyCode} wallet`.trim()}
        content="You can only create one Litireso Wallet per account."
      />
    </Container>
  );
};

export default CreateWalletOnboarding;
