import { DeepPartial } from '@reduxjs/toolkit';
import { Dayjs } from 'dayjs';
import { VFC } from 'react';
import DatePicker, { DatePickerProps } from '../components/app/DatePicker';
import useFormController from '../hooks/useFormController';
import { FieldProps } from './FieldControl';

type DatePickerFieldProps = FieldProps & DeepPartial<DatePickerProps>;

const DatePickerField: VFC<DatePickerFieldProps> = (props) => {
  const { field: { ref, ...field }, hasError, helperText } = useFormController<Dayjs>({
    name: props.name,
    defaultValue: props.value as Dayjs,
    helperText: props.helperText,
    rules: props.rules,
    onChange: props.onChange,
  });

  return (
    <DatePicker
      {...props}
      {...field}
      inputRef={ref}
      error={hasError}
      helperText={helperText}
      onChange={(date: Dayjs | null) => {
        field.onChange?.(date?.toString() ?? '');
      }}
    />
  );
};

export default DatePickerField;
