import { LoadingButton } from '@mui/lab';
import { Box, Stack, Button } from '@mui/material';
import { FilePondFile, LoadServerConfigFunction, ProcessServerConfigFunction } from 'filepond';
import {
  FC,
  useCallback, useEffect, useState,
} from 'react';
import Vapor from 'laravel-vapor';
import { FilePondProps, FilePond, registerPlugin } from 'react-filepond';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import FilePondPluginImageValidateSize from 'filepond-plugin-image-validate-size';

import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';

import { getAuthToken } from '../../helpers/cookies';
import { MediaType } from '../../types/app';
import {
  useSaveUploadedFileMutation,
} from '../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import useAppDispatch from '../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { addSnackbar } from '../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { ErrorType, getErrorMessage } from '../../helpers/handleSubmitAction';
import { baseApiHostname } from '../../helpers/config';

registerPlugin(
  FilePondPluginImageExifOrientation,
  FilePondPluginImagePreview,
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize,
  FilePondPluginImageValidateSize,
);

type UploadFileProps = {
  defaultValue?: string;
  acceptedFileTypes?: string[];
  params?: Record<string, string>;
  maxFileSize?: string;
  imageValidateSizeMaxWidth?: number;
  imageValidateSizeMaxHeight?: number;
  imageValidateSizeMinWidth?: number;
  imageValidateSizeMinHeight?: number;
  previewUrl?: string;
}

const UploadFile: FC<UploadFileProps> = ({
  defaultValue,
  acceptedFileTypes,
  maxFileSize,
  params = {},
  previewUrl,
  ...props
}) => {
  const [files, setFiles] = useState<Required<FilePondProps>['files']>([]);
  const [fileId, setFileId] = useState('');

  const [
    saveUploadedFileAction,
    {
      isLoading, isSuccess, data: uploadResponse,
    },
  ] = useSaveUploadedFileMutation();

  const dispatch = useAppDispatch();

  const handleUpdateFiles = useCallback((newFiles: FilePondFile[]) => {
    setFiles(newFiles.map((item) => item.file));
  }, []);

  const handleProcess = useCallback<ProcessServerConfigFunction>(async (
    __,
    file,
    _,
    load,
    error,
    progress,
  ) => {
    try {
      const response = await Vapor.store(file as unknown as File, {
        baseURL: baseApiHostname,
        headers: {
          Authorization: `Bearer ${getAuthToken()}`,
        },
        progress: (length) => {
          const loadedAmount = Math.round(length * 100);

          progress(true, loadedAmount, 100);
        },
      });

      load(response.url);
      setFileId(response.key);
    } catch (exception) {
      error((exception as Error).message);
    }
  }, []);

  const handleSave = useCallback(async () => {
    try {
      await saveUploadedFileAction({
        fileId,
        slug: params.slug,
        type: params.type as MediaType,
      })
        .unwrap();
    } catch (error) {
      const exception = error as ErrorType;
      if (exception.status !== 500) {
        dispatch(addSnackbar({
          message: getErrorMessage(exception),
          variant: 'error',
        }));
      }
    }
  }, [dispatch, fileId, params.slug, params.type, saveUploadedFileAction]);

  const handleLoad = useCallback<LoadServerConfigFunction>((
    source: string,
    load,
    error,
    progress,
    abort,
  ) => {
    const request = new XMLHttpRequest();
    request.open('GET', source);

    request.responseType = 'blob';

    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    request.onload = () => {
      if (request.status >= 200 && request.status < 300) {
        load(request.response as Blob);
      } else {
        error(request.response as string);
      }
    };

    request.send();

    return {
      abort: () => {
        request.abort();
        abort();
      },
    };
  }, []);

  useEffect(() => {
    if (defaultValue) {
      setFiles((state) => [
        { source: defaultValue, options: { type: 'local' } },
        ...state,
      ] as unknown as Required<FilePondProps>['files']);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (isSuccess) {
      setFileId('');
      dispatch(addSnackbar({
        message: uploadResponse?.message as string,
        variant: 'success',
      }));
    }
  }, [dispatch, isSuccess, uploadResponse?.message]);

  return (
    <Box marginBottom={4}>
      <FilePond
        files={files as FilePondProps['files']}
        onupdatefiles={handleUpdateFiles}
        acceptedFileTypes={acceptedFileTypes}
        name="file"
        allowSyncAcceptAttribute
        maxFiles={1}
        maxFileSize={maxFileSize}
        checkValidity
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        credits={false}
        dropValidation
        server={{
          process: handleProcess,
          load: handleLoad,
          headers: {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            Authorization: `Bearer ${getAuthToken()!}`,
          },
        }}
        {...props}
      />

      <Stack flexDirection="row" alignItems="center" columnGap={2}>
        <LoadingButton
          variant="contained"
          onClick={handleSave}
          loading={isLoading}
          disabled={!fileId}
        >
          Save
        </LoadingButton>

        {defaultValue && previewUrl && (
        <Button
          target="_blank"
          rel="noreferrer"
          title="Preview"
          component="a"
          variant="text"
          href={previewUrl}
        >
          Preview
        </Button>
        )}
      </Stack>
    </Box>
  );
};

export default UploadFile;
