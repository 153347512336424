import path from 'path';
import { Link, Tab } from '@mui/material';
import { Redirect, RouteComponentProps, Router } from '@reach/router';
import { FC, useMemo } from 'react';
import stripTrailingSlash from '../../../../helpers/stripTrailingSlash';
import Tabs from '../../../app/Tabs';
import Bank from './Bank';

const Settings: FC<RouteComponentProps> = ({ location }) => {
  const navLinks = useMemo(() => [
    {
      text: 'Bank',
      subPath: 'bank',
    },
  ], []);

  return (
    <>
      <Tabs
        allowScrollButtonsMobile
        variant="scrollable"
        value={stripTrailingSlash(location?.pathname || '')}
        marginBottom={3}
      >
        {navLinks.map((item) => (
          <Tab
            label={item.text}
            key={item.text}
            value={path.join('/app/dashboard/settings', item.subPath)}
            href={path.join('/app/dashboard/settings', item.subPath)}
            component={Link}
          />
        ))}
      </Tabs>

      <Router basepath="app/dashboard/settings">
        <Redirect from="/" to="bank" noThrow />
        <Bank default path="/bank" />
      </Router>
    </>
  );
};

export default Settings;
