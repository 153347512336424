import { ListSubheader, styled } from '@mui/material';
import { useCallback, FC } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem, { treeItemClasses } from '@mui/lab/TreeItem';
import Container from '../../app/Container';

export type TocItem = {
  title: string;
  value: string;
  handler: (toc: TocItem) => Promise<void> | void;
  children: TocItem[];
};

type ChapterListProps = {
  items: TocItem[];
  defaultSelected?: string;
}

const StyledTreeItemRoot = styled(TreeItem)(({ theme }) => ({
  [`& .${treeItemClasses.content}`]: {
    color: theme.palette.text.secondary,
    paddingRight: theme.spacing(2),
    paddingLeft: theme.spacing(2),
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '&.Mui-expanded': {
      fontWeight: theme.typography.fontWeightRegular,
    },
    '&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
    '&.Mui-focused, &.Mui-selected, &.Mui-selected.Mui-focused': {
      backgroundColor: theme.palette.action.selected,
      color: 'var(--tree-view-color)',
    },
    [`& .${treeItemClasses.label}`]: {
      fontWeight: 'inherit',
      color: 'inherit',
      paddingLeft: 0,
    },
  },
  [`& .${treeItemClasses.iconContainer}`]: {
    marginRight: 0,
    width: 0,
  },
}));

const ChapterList: FC<ChapterListProps> = ({
  items,
  defaultSelected,
}) => {
  const renderTree = useCallback((nodes: TocItem) => (
    <StyledTreeItemRoot
      key={nodes.value}
      nodeId={nodes.value}
      label={nodes.title}
      onClick={() => nodes.handler(nodes)}
    >
      {Array.isArray(nodes.children) && nodes.children.map((node) => renderTree(node))}
    </StyledTreeItemRoot>
  ), []);

  return (
    <Container>
      <ListSubheader>Chapters</ListSubheader>

      <TreeView
        defaultCollapseIcon={<ExpandMoreIcon />}
        defaultExpanded={defaultSelected ? [defaultSelected] : undefined}
        defaultSelected={defaultSelected ? [defaultSelected] : undefined}
        defaultExpandIcon={<ChevronRightIcon />}
      >
        {items.map((item) => renderTree(item))}
      </TreeView>
    </Container>
  );
};

export default ChapterList;
