import path from 'path';
import {
  Stack, Typography,
  Box, Skeleton, Chip, Link,
  Button,
  Grid,
  Tab,
} from '@mui/material';
import { RouteComponentProps, Router } from '@reach/router';
import { skipToken } from '@reduxjs/toolkit/dist/query';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  useGetPublicationQuery,
  usePublishPublicationMutation,
} from '../../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import Container from '../../../../app/Container';
import LoadingButton from '../../../../app/LoadingButton';
import CircularProgress from '../../../../app/CircularProgress';
import { ErrorType, getErrorMessage } from '../../../../../helpers/handleSubmitAction';
import IconEmpty from '../../../../icons/IconEmpty';
import SEO from '../../../../app/SEO';
import AlertDialog from '../../../../app/AlertDialog';
import Tabs from '../../../../app/Tabs';
import stripTrailingSlash from '../../../../../helpers/stripTrailingSlash';
import BasicInfo from './BasicInfo';
import CollaboratorsTab from './CollaboratorsTab';
import PricingsTab from './PricingsTab';
import MediaTab from './MediaTab';
import Earnings from './Earnings';

const ManagePublication: FC<RouteComponentProps<{ pathName: string }>> = ({ pathName = '', location }) => {
  const [slug] = pathName.split('/');

  const [publishError, setPublishError] = useState<string|null>(null);

  const { data: book, isLoading, isFetching } = useGetPublicationQuery(slug || skipToken);

  const [publishPublicationAction, { isLoading: isPublishing }] = usePublishPublicationMutation();

  const isDraft = useMemo(() => book?.status === 'draft', [book?.status]);

  const navLinks = useMemo(() => [
    {
      text: 'Basic Info',
      subPath: '',
    },
    {
      text: 'Media',
      subPath: 'media',
    },
    {
      text: 'Collaborators',
      subPath: 'collaborators',
    },
    {
      text: 'Pricings',
      subPath: 'pricings',
    },
    {
      text: 'Earnings',
      subPath: 'earnings',
    },
  ], []);

  const handlePublish = useCallback(async () => {
    try {
      await publishPublicationAction(slug).unwrap();
    } catch (error) {
      const exception = error as ErrorType;
      if (exception.status !== 500) {
        setPublishError(getErrorMessage(exception));
      }
    }
  }, [publishPublicationAction, slug]);

  if (!book && (!isLoading || !isFetching)) {
    return (
      <Container maxWidth="sm" sx={{ textAlign: 'center' }}>
        <IconEmpty />
        <Typography marginTop={3} marginBottom={3}>Publication does not exist</Typography>

        <Button variant="outlined" href="../" size="small">View all publications</Button>
      </Container>
    );
  }

  return (
    <Container>
      <SEO title={slug ? `Manage ${book?.title}` : 'Add a new publication'} />
      <AlertDialog
        title="Error"
        isOpen={!!publishError}
        onClose={() => setPublishError(null)}
        content={publishError}
      />
      <Box
        marginTop={4}
        marginBottom={4}
      >
        <Grid
          container
          justifyContent="space-between"
          alignItems="center"
          rowSpacing={2}
          marginBottom={2}
        >
          {isLoading
            ? (
              <>
                <Skeleton variant="text" width={300} height={40} />
                <Skeleton variant="rectangular" width={200} height={40} />
              </>
            )
            : (
              <>
                <Grid item xs={12} md="auto">
                  <Stack flexDirection="row" alignItems="center" columnGap={2}>
                    <Typography variant="h4" fontWeight="600">
                      {book?.title}
                    </Typography>
                    <Chip label={book?.type} size="small" />
                    <Chip
                      label={book?.status}
                      size="small"
                      color={book?.status === 'published' ? 'success' : 'default'}
                    />
                  </Stack>
                </Grid>
                <Grid item xs={12} md="auto">
                  <Stack gap={2} flexDirection="row">
                    <Button component={Link} href={`/b/${book?.slug}`} variant="outlined">Preview</Button>
                    <LoadingButton
                      disabled={!isDraft}
                      loading={isPublishing}
                      onClick={handlePublish}
                    >
                      {isDraft ? 'Publish' : 'Published'}
                    </LoadingButton>
                  </Stack>
                </Grid>
              </>
            )}
        </Grid>
        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            value={stripTrailingSlash(location?.pathname || '')}
          >
            {navLinks.map((item) => (
              <Tab
                label={item.text}
                key={item.text}
                value={path.join(`/app/dashboard/publications/${slug}`, item.subPath)}
                href={path.join(`/app/dashboard/publications/${slug}`, item.subPath)}
                component={Link}
              />
            ))}
          </Tabs>
        </Box>
      </Box>
      <Container maxWidth="md" sx={{ marginLeft: 'unset', paddingBottom: 5 }} disableGutters>
        {isLoading && <CircularProgress center />}
        {!isLoading && !!book && (
          <Router basepath={`app/dashboard/publications/${slug}`}>
            <BasicInfo book={book} path="/" slug={slug} />
            <MediaTab book={book} path="media" slug={slug} />
            <CollaboratorsTab
              path="collaborators"
              book={book}
              isLoading={isLoading || isFetching}
              hasCollaborators={!!book.collaborators.some((collaborator) => collaborator.type === 'author')}
              slug={slug}
            />
            <PricingsTab path="pricings" book={book} isLoading={isLoading || isFetching} slug={slug} />
            <Earnings path="earnings/*" slug={slug} />
          </Router>
        )}
      </Container>
    </Container>
  );
};

export default ManagePublication;
