import TextField from '@mui/material/TextField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {
  DatePickerProps as BaseDatePickerProps,
  DatePicker as BaseDatePicker,
  MobileDatePicker,
} from '@mui/x-date-pickers';
import { VFC } from 'react';
import { Dayjs } from 'dayjs';
import FieldLabel from '../../field/FieldLabel';
import { BaseComponentFieldProps } from '../../types/app';
import Desktop from './Desktop';
import Mobile from './Mobile';

export type DatePickerProps = Omit<BaseDatePickerProps<Dayjs, Dayjs>, 'renderInput'> & BaseComponentFieldProps;

const DatePicker: VFC<DatePickerProps> = ({
  label,
  id,
  helperText,
  ...props
}) => (
  <LocalizationProvider dateAdapter={AdapterDayjs}>
    <FieldLabel
      content={label}
      htmlFor={id}
      hasError={props.error}
      disabled={props.disabled}
    />

    <Desktop>
      <BaseDatePicker<Dayjs>
        {...props}
        renderInput={(params) => (
          <TextField {...params} helperText={helperText} error={props.error} size="small" />
        )}
      />
    </Desktop>

    <Mobile>
      <MobileDatePicker<Dayjs>
        {...props}
        renderInput={(params) => (
          <TextField {...params} helperText={helperText} error={props.error} size="small" />
        )}
      />
    </Mobile>

    {/* {helperText && <FormHelperText error={props.error}>{helperText}</FormHelperText>} */}
  </LocalizationProvider>
);

export default DatePicker;
