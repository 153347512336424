import { RadioGroupProps as BaseRadioGroupProps, RadioGroup as BaseRadioGroup } from '@mui/material';
import { VFC } from 'react';

export type RadioGroupProps = BaseRadioGroupProps;

const RadioGroup: VFC<RadioGroupProps> = (props) => (
  <BaseRadioGroup {...props} />
);

export default RadioGroup;
