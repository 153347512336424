import { Redirect, Router } from '@reach/router';
import PrivateRoute from '../../components/app/PrivateRoute';
import CreateWalletOnboarding from '../../components/pages/onboarding/CreateWalletOnboarding';
import Publications from '../../components/pages/Dashboard/Publications';
import ManagePublication from '../../components/pages/Dashboard/Publications/ManagePublication';
import DashboardLayout from '../../layouts/DashboardLayout';
import Reader from '../../components/pages/Reader';
import GeneralLayout from '../../layouts/GeneralLayout';
import Integrations from '../../components/pages/Dashboard/Integrations';
import Settings from '../../components/pages/Dashboard/Settings';

const DashboardRoute = () => (
  <DashboardLayout>
    <Router>
      <Redirect from="/" to="publications" noThrow />
      <Publications path="publications" />
      <ManagePublication path="publications/*pathName" />
      <Settings path="settings/*pathName" />
      <Integrations path="integrations" />
    </Router>
  </DashboardLayout>
);

const WalletOnboardingRoute = () => (
  <GeneralLayout>
    <CreateWalletOnboarding />
  </GeneralLayout>
);

const App = () => (
  <Router basepath="/app">
    <PrivateRoute path="/onboarding" component={WalletOnboardingRoute} />
    <PrivateRoute path="/dashboard/*" component={DashboardRoute} />
    <PrivateRoute path="/reader/:slug" component={Reader} />
  </Router>
);

export default App;
