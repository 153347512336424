import {
  Backdrop, CircularProgress, DialogActions, DialogContent,
} from '@mui/material';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  useAddBankMutation,
  useLazyGetBankBranchesQuery,
  useLazyGetBanksByCountriesQuery,
} from '../../../../../../plugins/gatsby-plugin-redux/store/api/flutterwave/bank.api';
import AutocompleteField from '../../../../../field/AutocompleteField';
import { FieldProps } from '../../../../../field/FieldControl';
import Form from '../../../../../field/Form';
import InputField from '../../../../../field/InputField';
import SelectField from '../../../../../field/SelectField';
import handleSubmitAction from '../../../../../helpers/handleSubmitAction';
import { isAutocompleteRequired, isRequired } from '../../../../../helpers/validators';
import { SubmitHandler } from '../../../../../types/app';
import { AddBankFormData } from '../../../../../types/form';
import { BankSchema, BankBranchSchema } from '../../../../../types/schema/bank.schema';
import Dialog from '../../../../app/Dialog';
import DialogTitle from '../../../../app/DialogTitle';
import ErrorAlert from '../../../../app/ErrorAlert';
import LoadingButton from '../../../../app/LoadingButton';

type AddBankModalProps = {
  isOpen: boolean;
  onClose: () => void;
}

const countries = [
  { value: 'NG', text: 'Nigeria' },
  { value: 'GH', text: 'Ghana' },
  { value: 'KE', text: 'Kenya' },
  { value: 'UG', text: 'Uganda' },
  { value: 'ZA', text: 'South Africa' },
  { value: 'TZ', text: 'Tanzania' },
];

const currencyMap = {
  NG: 'NGN',
  GH: 'GHS',
  KE: 'KES',
  UG: 'UGX',
  ZA: 'ZAR',
  TZ: 'TZS',
};

type CurrencyMap = keyof typeof currencyMap

const AddBankModal: FC<AddBankModalProps> = ({ isOpen, onClose }) => {
  const [fetchBanks, {
    data: banks = [],
    isLoading: isBanksLoading,
    isFetching: isBanksFetching,
  }] = useLazyGetBanksByCountriesQuery();

  const [getBankBranches, {
    data: branches = [],
    isLoading: isBranchesLoading,
    isFetching: isFetchingBranches,
  }] = useLazyGetBankBranchesQuery();

  const [addBankAction, { isLoading: isAddingBank }] = useAddBankMutation();
  const [selectedCountry, setSelectedCountry] = useState<CurrencyMap>();

  const currencies = useMemo(() => {
    if (!selectedCountry) {
      return [];
    }

    return [
      { text: currencyMap[selectedCountry], value: currencyMap[selectedCountry] },
      { text: 'USD', value: 'USD' },
    ];
  }, [selectedCountry]);

  const onSubmit = useCallback<SubmitHandler<AddBankFormData>>(async (values) => {
    await addBankAction({
      ...values,
      bankCode: (values.bankCode as unknown as BankSchema).code,
    }).unwrap();
    onClose();
  }, [addBankAction, onClose]);

  const handleCountry = useCallback<Required<FieldProps>['onChange']>((value) => {
    if (value as string) {
      setSelectedCountry(value as CurrencyMap);
      void fetchBanks(value as string);
    }
  }, [fetchBanks]);

  const handleBankChange = useCallback((bank) => {
    const value = (bank as BankSchema | null);

    if (!value) {
      return;
    }

    void getBankBranches(value.id);
  }, [getBankBranches]);

  const handleBankBranch = useCallback(() => {
    //
  }, []);

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="sm" fullWidth persistent={isAddingBank}>
      <DialogTitle>Add bank details</DialogTitle>
      <Form<AddBankFormData>
        initialValues={{
          accountName: '',
          accountNumber: '',
          bankCode: '',
          branchCode: '',
          country: '',
          currencyCode: '',
        }}
        onSubmit={handleSubmitAction(onSubmit)}
      >
        {(config) => (
          <>
            <DialogContent>
              <ErrorAlert message={config.errors._server?.message} />
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isBranchesLoading || isFetchingBranches}
              >
                <CircularProgress color="inherit" />
              </Backdrop>

              <SelectField
                options={[{ text: 'Please select a country', value: '' }, ...countries]}
                name="country"
                rules={[isRequired]}
                onChange={handleCountry}
                label="Country"
                fullWidth
              />

              <AutocompleteField<BankSchema>
                name="bankCode"
                options={banks}
                label="Bank"
                loading={isBanksLoading || isBanksFetching}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                placeholder="Please select a bank"
                onChange={handleBankChange}
                rules={[isAutocompleteRequired('code')]}
                disabled={!config.values.country}
              />

              {!!branches.length && (
              <AutocompleteField<BankBranchSchema>
                name="branchCode"
                options={branches}
                label="Bank Branch"
                loading={isBranchesLoading || isFetchingBranches}
                getOptionLabel={(option) => option.name || ''}
                isOptionEqualToValue={(option, value) => option.code === value.code}
                placeholder="Please select a bank branch"
                onChange={handleBankBranch}
                rules={[isAutocompleteRequired('code')]}
                disabled={!config.values.bankCode && !(isBranchesLoading || isFetchingBranches)}
              />
              )}

              {!!config.values.bankCode && (
              <InputField
                label="Account Number / Phone Number"
                name="accountNumber"
                rules={[isRequired]}
                fullWidth
                disabled={branches.length ? !config.values.branchCode : !config.values.bankCode}
              />
              )}

              {!!config.values.bankCode && (
              <InputField
                label="Account Nane"
                name="accountName"
                rules={[isRequired]}
                fullWidth
                disabled={branches.length ? !config.values.branchCode : !config.values.bankCode}
              />
              )}

              {!!config.values.bankCode && (
              <SelectField
                options={currencies}
                name="currencyCode"
                rules={[isRequired]}
                label="Primary currency"
                fullWidth
              />
              )}

            </DialogContent>
            <DialogActions>
              <LoadingButton
                loading={isAddingBank}
                onClick={config.handleSubmit}
                fullWidth
              >
                Save bank details
              </LoadingButton>
            </DialogActions>
          </>
        )}
      </Form>
    </Dialog>
  );
};

export default AddBankModal;
