import { Edit } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Toolbar, DialogContent, DialogTitle, DialogActions, Alert,
} from '@mui/material';
import {
  DataGrid, GridActionsCellItem, GridRowParams,
} from '@mui/x-data-grid';
import { RouteComponentProps } from '@reach/router';
import { graphql, StaticQuery } from 'gatsby';
import {
  useEffect, useMemo,
  useState, useCallback, VFC, ChangeEvent,
} from 'react';
import { useAddPricingMutation, useUpdatePricingTypeMutation } from '../../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/publication.api';
import Form from '../../../../../field/Form';
import InputField from '../../../../../field/InputField';
import SelectField from '../../../../../field/SelectField';
import handleSubmitAction from '../../../../../helpers/handleSubmitAction';
import { isNumber, isRequired } from '../../../../../helpers/validators';
import { PricingSchemaType, SubmitHandler } from '../../../../../types/app';
import { AddPricingFormData } from '../../../../../types/form';
import { BookSchema } from '../../../../../types/schema';
import Dialog from '../../../../app/Dialog';
import ErrorAlert from '../../../../app/ErrorAlert';
import HtmlForm from '../../../../app/HtmlForm';
import Spacer from '../../../../app/Spacer';
import Switch from '../../../../app/Switch';
import Button from '../../../../app/Button';

type PricingsTabProps = {
  book: BookSchema;
  isLoading: boolean;
  slug: string;
}

const query = graphql`
  query Currency {
    allCurrency {
      nodes {
        code
      }
    }
  }
`;

const PricingsTab: VFC<RouteComponentProps & PricingsTabProps> = ({
  book,
  isLoading,
  slug,
}) => {
  const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
  const [selectedPricing, setSelectedPricing] = useState<PricingSchemaType | null>(null);

  const [addPricingAction, { isLoading: isAddingPricing }] = useAddPricingMutation();
  const [updatePricingTypeAction] = useUpdatePricingTypeMutation();

  const isPaid = useMemo(() => book.pricingType === 'paid', [book.pricingType]);
  const hasPricings = useMemo(() => !!book.pricings?.length, [book.pricings]);
  const isPrint = useMemo(() => book.type === 'print', [book.type]);

  const onSubmit = useCallback<SubmitHandler<AddPricingFormData>>(async (values, helpers) => {
    await addPricingAction({ ...values, slug, id: selectedPricing?.id }).unwrap();
    setIsAddDialogOpen(false);
    helpers.resetForm();
  }, [addPricingAction, selectedPricing?.id, slug]);

  const renderGetActions = useCallback((params: GridRowParams) => [
    <GridActionsCellItem icon={<Edit />} onClick={() => setSelectedPricing(params.row as PricingSchemaType)} label="Edit" />,
  ], []);

  const handleChangePricingType = useCallback(async (event: ChangeEvent<HTMLInputElement>) => {
    await updatePricingTypeAction({ pricingType: event.target.checked ? 'free' : 'paid', slug }).unwrap();
  }, [slug, updatePricingTypeAction]);

  useEffect(() => {
    setIsAddDialogOpen(!!selectedPricing);
  }, [selectedPricing]);

  useEffect(() => {
    if (!isAddDialogOpen) {
      setSelectedPricing(null);
    }
  }, [isAddDialogOpen]);

  return (
    <>
      <Dialog
        open={isAddDialogOpen}
        onClose={() => setIsAddDialogOpen(false)}
        maxWidth="sm"
        fullWidth
        persistent={isAddingPricing}
      >
        <DialogTitle>
          {selectedPricing ? 'Edit' : 'Add'}
          {' '}
          pricing
        </DialogTitle>
        <Form<AddPricingFormData>
          initialValues={{
            currencyCode: selectedPricing ? selectedPricing.currencyCode : 'USD',
            amount: selectedPricing ? selectedPricing.amount.toString() : '',
          }}
          onSubmit={handleSubmitAction(onSubmit)}
        >
          {(config) => (
            <HtmlForm onSubmit={config.handleSubmit}>
              <DialogContent>
                <ErrorAlert message={config.errors._server?.message} />
                <StaticQuery<Queries.CurrencyQuery> query={query}>
                  {({ allCurrency }) => (
                    <SelectField
                      name="currencyCode"
                      fullWidth
                      label="Currency code"
                      readOnly={!!selectedPricing}
                      options={allCurrency.nodes.map((item) => ({
                        text: item.code,
                        value: item.code,
                      }))}
                      rules={[isRequired]}
                    />
                  )}
                </StaticQuery>
                <InputField name="amount" fullWidth label="Amount" rules={[isRequired, isNumber]} />
              </DialogContent>
              <DialogActions>
                <LoadingButton variant="text" type="submit" loading={isAddingPricing}>Submit</LoadingButton>
              </DialogActions>
            </HtmlForm>
          )}
        </Form>
      </Dialog>

      {!isPaid && (
      <Alert severity="info" sx={{ marginBottom: 3 }}>
        This publication is listed as free. Adding a pricing will change its status to paid
      </Alert>
      )}
      <Toolbar>
        {hasPricings && !isPrint && (
          <Switch
            label="Do not list book as paid"
            name="pricingType"
            checked={!hasPricings}
            onChange={handleChangePricingType}
          />
        )}
        <Spacer />
        <Button
          disableElevation
          onClick={() => setIsAddDialogOpen(true)}
          disabled={hasPricings}
        >
          Add Pricing
        </Button>
      </Toolbar>

      <div style={{ width: '100%' }}>
        <DataGrid
          autoHeight
          rows={book.pricings || []}
          loading={isLoading}
          columns={[
            {
              field: 'currencyCode',
              headerName: 'Currency',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            },
            {
              field: 'amount',
              headerName: 'Amount',
              sortable: false,
              filterable: false,
              disableColumnMenu: true,
            },
            {
              field: 'action',
              disableColumnMenu: true,
              type: 'actions',
              getActions: renderGetActions,
            },
          ]}
          pageSize={20}
          hideFooterSelectedRowCount
          disableSelectionOnClick
          autoPageSize
        />
      </div>
    </>
  );
};

export default PricingsTab;
