import {
  Box, Grid, Stack, Tab, TabsProps, Typography, useTheme,
} from '@mui/material';
import { RouteComponentProps } from '@reach/router';
import { graphql, useStaticQuery } from 'gatsby';
import { Bookshelf } from 'mdi-material-ui';
import {
  FC, useCallback, useMemo, useState,
} from 'react';
import {
  LineChart, Line,
  XAxis, YAxis, Tooltip,
  Legend, ResponsiveContainer,
} from 'recharts';
import useCurrencyChooser from '../../../../../../../plugins/gatsby-plugin-currency-chooser/src/useCurrencyChooser';
import useAppDispatch from '../../../../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useGetSummaryQuery } from '../../../../../../../plugins/gatsby-plugin-redux/store/api/dashboard/summary.api';
import { addSnackbar } from '../../../../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import { getCurrencySymbol } from '../../../../../../helpers/formatAmount';
import Money from '../../../../../../services/Money';
import { CurrencyCodeType } from '../../../../../../types/app';
import Button from '../../../../../app/Button';
import Container from '../../../../../app/Container';
import Tabs from '../../../../../app/Tabs';
import TransactionList from '../../../../profile/TransactionList';
import WithdrawWalletModal from './WithdrawWalletModal';

const Earnings: FC<RouteComponentProps & { slug: string; }> = ({ slug }) => {
  const theme = useTheme();
  const { currencyCode, setCurrencyCode } = useCurrencyChooser();
  const [isWithdrawalModalOpen, setIsWithdrawalModalOpen] = useState(false);
  const { allCurrency } = useStaticQuery<Queries.EarningsTabCurrencyQuery>(graphql`
  query EarningsTabCurrency {
    allCurrency {
      nodes {
        code
      }
    }
  }
`);

  const dispatch = useAppDispatch();

  const {
    data: response,
    isLoading: isLoadingTransactions,
    isFetching: isFetchingTransactions,
    refetch,
  } = useGetSummaryQuery({ slug, currencyCode });

  const overview = useMemo(() => [
    {
      title: 'TOTAL EARNINGS',
      value: Money.of(Number(response?.summary?.amountEarned || 0), currencyCode).toFormat(),
      color: '#042241',
    },
    {
      title: 'TOTAL PAYOUTS',
      value: Money.of(Number(response?.summary?.totalPayouts || 0), currencyCode).toFormat(),
      color: '#004E89',
    },
    {
      title: 'NUMBER OF PURCHASES',
      value: Number(response?.summary?.purchaseCount || 0),
      color: '#1882E9',
    },
  ], [currencyCode,
    response?.summary?.amountEarned,
    response?.summary?.purchaseCount,
    response?.summary?.totalPayouts,
  ]);

  const [integral, fractional = '00'] = (response?.summary.balance || 0).toString().split('.');

  const handleTabChange = useCallback<Required<TabsProps>['onChange']>((_, selectedCurrencyCode: CurrencyCodeType) => (
    setCurrencyCode(selectedCurrencyCode)
  ), [setCurrencyCode]);

  const handleWithdrawal = useCallback(() => {
    setIsWithdrawalModalOpen(false);
    dispatch(addSnackbar({
      message: 'Withdrawal is successful.',
      variant: 'success',
    }));
  }, [dispatch]);

  return (
    <Container sx={{ paddingBottom: 5 }} maxWidth="lg">
      <Box marginBottom={3} textAlign="center">
        <Typography gutterBottom variant="body2" color="text.secondary">Available balance</Typography>
        <Stack flexDirection="row" alignItems="baseline" justifyContent="center" marginBottom={2}>
          <Typography variant="h5" component="span">
            {getCurrencySymbol(currencyCode)}
          </Typography>
          <Typography variant="h3" component="span" fontWeight="bold">
            {integral}
          </Typography>
          <Typography variant="h5" component="span">
            {`.${fractional}`}
          </Typography>
        </Stack>
        <Button
          disabled={!response?.summary.balance}
          onClick={() => setIsWithdrawalModalOpen(true)}
        >
          {`Withdraw ${currencyCode} Earnings`}
        </Button>
        {isWithdrawalModalOpen && (
        <WithdrawWalletModal
          isOpen={isWithdrawalModalOpen}
          onClose={() => setIsWithdrawalModalOpen(false)}
          currencyCode={currencyCode}
          slug={slug}
          balance={response?.summary.balance || 0}
          onComplete={handleWithdrawal}
        />
        )}
      </Box>
      <Box marginBottom={6}>
        <Tabs
          value={currencyCode}
          onChange={handleTabChange}
          hideUnderline
          marginBottom={3}
        >
          {allCurrency.nodes.map((currency) => (
            <Tab key={currency.code} label={currency.code} value={currency.code} />
          ))}
        </Tabs>

        <Grid container spacing={2}>
          {overview.map((item) => (
            <Grid item md={4} lg={4} xs={12} key={item.title}>
              <Box
                sx={{ backgroundColor: item.color, color: '#fff' }}
                padding={3}
                height="100%"
                borderRadius={5}
              >
                <Grid container justifyContent="space-between">
                  <Grid item md={8} xs="auto" display="flex" flexDirection="column" justifyContent="center">
                    <Typography variant="body2">{item.title}</Typography>
                    <Typography fontWeight="600">{item.value}</Typography>
                  </Grid>
                  <Grid item md="auto" xs="auto">
                    <Box
                      display="flex"
                      justifyContent="center"
                      alignItems="center"
                      borderRadius="50%"
                      width={58}
                      height={58}
                      sx={{ backgroundColor: '#fff', fontSize: 30 }}
                    >
                      <Bookshelf fontSize="inherit" color="disabled" />
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart
          width={400}
          height={300}
          data={response?.summary?.graph || []}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <XAxis dataKey="transaction_date" />
          <YAxis
            tickFormatter={(value: number) => (
              Money.of(!Number.isFinite(value) ? 0 : value, currencyCode).toFormat()
            )}
          />
          <Tooltip
            formatter={(value: number) => (
              Money.of(!Number.isFinite(value) ? 0 : value, currencyCode).toFormat()
            )}
          />
          <Legend />
          <Line type="monotone" dataKey="earned" stroke={theme.palette.success.main} activeDot={{ r: 8 }} name="Earnings" />
        </LineChart>
      </ResponsiveContainer>

      <Box marginTop={6}>
        <TransactionList
          isLoading={isLoadingTransactions || isFetchingTransactions}
          refetch={refetch}
          transactions={response?.data || []}
          depositLabel="Earnings"
          withdrawLabel="Payouts"
          isAuthor
          noDataMessage="No transactions yet."
        />
      </Box>
    </Container>
  );
};

export default Earnings;
