import path from 'path';
import {
  Alert,
  Box,
} from '@mui/material';
import {
  Book, Contents, Location, NavItem, Rendition,
} from 'epubjs';
import { EVENTS } from 'epubjs/src/utils/constants';
import {
  useEffect, useRef, useState,
  useMemo, useCallback, FC,
} from 'react';
import request from 'epubjs/src/utils/request';
import { BookOptions } from 'epubjs/types/book';
import SEO from '../../../app/SEO';
import Application from '../../../app/Application';
import Content from '../../../app/Content';
import Toolbar from '../Toolbar';
import { getAuthToken } from '../../../../helpers/cookies';
import { TocItem } from '../ChapterList';

type EpubReaderProps = {
  file: string;
  title: string;
  slug: string;
  onLoadSuccess: () => void;
  isLoaded: boolean;
}

const publicAssets = ['otf', 'eot', 'ttf', 'woff', 'woff2', 'sfnt', 'css', 'jpg', 'png', 'gif', 'jpeg', 'bmp', 'svg'];

const EpubReader: FC<EpubReaderProps> = ({
  file,
  title,
  slug,
  onLoadSuccess,
  isLoaded,
}) => {
  const [isNextBtnActive, setIsNextBtnActive] = useState(false);
  const [isPrevBtnActive, setIsPrevBtnActive] = useState(false);
  const [showError, setShowError] = useState(false);

  const epubRef = useRef<Book>();
  const areaRef = useRef<HTMLDivElement>();
  const rendition = useRef<Rendition>();
  const chapters = useRef<TocItem[]>([]);

  const isRTL = useMemo(() => epubRef.current?.packaging.metadata.direction === 'rtl', []);

  const handleNext = useCallback(() => {
    if (isRTL) {
      void rendition.current?.prev();
      return;
    }

    void rendition.current?.next();
  }, [isRTL]);

  const handlePrevious = useCallback(() => {
    if (isRTL) {
      void rendition.current?.next();
      return;
    }

    void rendition.current?.prev();
  }, [isRTL]);

  const handleKeyup = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowLeft') {
      return handlePrevious();
    }

    if (event.key === 'ArrowRight') {
      return handleNext();
    }

    return undefined;
  }, [handleNext, handlePrevious]);

  const resizeImages = useCallback((contents: Contents) => {
    const css = 'img { max-width: 100%; height: auto;}';
    const head = contents.document.getElementsByTagName('head')[0];
    const style = contents.document.createElement('style');
    style.setAttribute('type', 'text/css');
    style.appendChild(contents.document.createTextNode(css));
    head.appendChild(style);
  }, []);

  const handleRelocated = useCallback((location: Location) => {
    setIsNextBtnActive(!location.atEnd);
    setIsPrevBtnActive(!location.atStart);
  }, []);

  const handleChapterNavigation = useCallback(async (item: TocItem) => {
    await rendition?.current?.display(item.value);
  }, [rendition]);

  const transformToc = useCallback((toc: NavItem[]): TocItem[] => (
    toc.map<TocItem>((item) => ({
      title: item.label,
      value: item.href,
      handler: handleChapterNavigation,
      children: item.subitems?.length ? transformToc(item.subitems) : [],
    }))
  ), [handleChapterNavigation]);

  const loadResource = useCallback(
    async (url: string) => fetch(`${process.env.GATSBY_API_BASE_URL}/dashboard/reader/${slug}?file=${url}`, {
      headers: new Headers({
        Authorization: `Bearer ${getAuthToken()}`,
      }),
    }).then((response) => response.json<{ url: string }>())
      .then((response) => response.url),
    [slug],
  );

  useEffect(() => {
    if (areaRef.current) {
      epubRef.current = new Book(file, {
        openAs: new URL(file).pathname.endsWith('opf') ? 'opf' : 'directory',
        encoding: 'utf-8',
        requestMethod: async (url, type, credentials, headers) => {
          const relativePath = path.relative(file, url).slice(3);

          if (relativePath && !publicAssets.includes(relativePath.split('.').pop() || '')) {
            url = await loadResource(relativePath);
          }

          return (request(url, type, credentials as unknown as boolean, headers)) as unknown as Promise<Required<BookOptions>['requestMethod']>;
        },
      });

      epubRef.current.on(EVENTS.BOOK.OPEN_FAILED, () => {
        setShowError(true);
      });

      rendition.current = epubRef.current.renderTo(areaRef.current, {
        width: '100%',
        height: 'inherit',
        manager: 'continuous',
        flow: 'paginated',
        snap: true,
      });

      void epubRef.current.ready.then(() => {
        rendition.current?.hooks.content.register(resizeImages);
        void epubRef.current?.rendition.display().then(() => {
          void epubRef.current?.loaded.navigation.then((navigation) => {
            chapters.current = transformToc(navigation.toc);
          });

          onLoadSuccess();

          rendition.current?.on('relocated', handleRelocated);

          rendition.current?.on('keyup', handleKeyup);
          document.addEventListener('keyup', (event) => handleKeyup(event), false);
        });
      });
    }

    return () => {
      epubRef.current?.destroy();
      document.removeEventListener('keyup', handleKeyup, false);
    };
  }, [areaRef, handleKeyup,
    file, handleRelocated,
    resizeImages, transformToc,
    slug, loadResource,
    onLoadSuccess,
  ]);

  return (
    <Application>
      <SEO title={title ? `Reading ${title}` : 'Loading...'} />

      <Content>
        {!showError && (
          <Box
            ref={areaRef}
            sx={() => ({
              width: '100%',
              height: 'calc(100% - 64px)',
              margin: 'auto',
            })}
          />
        )}
        {showError && <Alert>Something terrible happened! Please refresh your browser!</Alert>}
      </Content>

      {isLoaded && (
      <Toolbar
        title={title}
        toc={chapters.current}
        defaultSelectedToc={rendition.current?.currentLocation()?.href}
        onNextPage={handleNext}
        onPreviousPage={handlePrevious}
        isNextBtnActive={isNextBtnActive}
        isPrevBtnActive={isPrevBtnActive}
      />
      )}
    </Application>
  );
};

export default EpubReader;
