import { BaseTextFieldProps } from '@mui/material';
import { forwardRef } from 'react';
import { NumericFormat } from 'react-number-format';
import { InputAttributes, NumericFormatProps } from 'react-number-format/types/types';
import { BaseComponentFieldProps } from '../../types/app';
import Input from './Input';

export type InputNumberProps = NumericFormatProps<InputAttributes>
  & BaseTextFieldProps & BaseComponentFieldProps;

const InputNumber = forwardRef<HTMLInputElement, InputNumberProps>(({
  onChange,
  ...props
}, ref) => (
  <NumericFormat
    {...props}
    getInputRef={ref}
    customInput={Input}
    decimalScale={2}
    valueIsNumericString
    onValueChange={(values) => {
      onChange?.({
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        /** @ts-ignore */
        target: {
          name: props.name,
          value: values.value,
        },
      });
    }}
    thousandSeparator
  />
));

export default InputNumber;
