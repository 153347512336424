import { AutocompleteValue, Box } from '@mui/material';
import Autocomplete, { AutocompleteProps } from '../components/app/Autocomplete';
import useFormController from '../hooks/useFormController';
import { FieldProps } from './FieldControl';

type AutocompleteFieldProps<
  T,
  Multiple extends boolean | undefined,
  DisableClearable extends boolean | undefined,
  FreeSolo extends boolean | undefined
> = FieldProps & AutocompleteProps<T, Multiple, DisableClearable, FreeSolo>;

const AutocompleteField = <
  T,
  Multiple extends boolean | undefined = undefined,
  DisableClearable extends boolean | undefined = undefined,
  FreeSolo extends boolean | undefined = undefined
  >({
    name,
    rules,
    value,
    onChange,
    noMargingBottom,
    ...props
  }: AutocompleteFieldProps<T, Multiple, DisableClearable, FreeSolo>) => {
  const {
    field: { ref, ...field }, fieldState,
    helperText,
  } = useFormController<AutocompleteValue<T, Multiple, DisableClearable, FreeSolo>>({
    name,
    rules,
    defaultValue: value,
    helperText: props.helperText,
    onChange,
  });

  return (
    <Box marginBottom={noMargingBottom ? undefined : 2} sx={{ flex: 1 }}>
      <Autocomplete<T, Multiple, DisableClearable, FreeSolo >
        {...props}
        inputRef={ref}
        {...field}
        helperText={helperText}
        error={!!fieldState.error?.message}
      />
    </Box>
  );
};

export default AutocompleteField;
