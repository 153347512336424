import { DialogActions, DialogContent } from '@mui/material';
import { FC, useCallback, useState } from 'react';
import useAppDispatch from '../../../../../../../plugins/gatsby-plugin-redux/hooks/useAppDispatch';
import { useGetUserBanksQuery } from '../../../../../../../plugins/gatsby-plugin-redux/store/api/flutterwave/bank.api';
import { useLazyGetTransferFeeQuery, useWithdrawEarningsMutation } from '../../../../../../../plugins/gatsby-plugin-redux/store/api/wallet.api';
import { addSnackbar } from '../../../../../../../plugins/gatsby-plugin-snackbar/store/snackbar';
import AutocompleteField from '../../../../../../field/AutocompleteField';
import Form from '../../../../../../field/Form';
import InputNumberField from '../../../../../../field/InputNumberField';
import InputPasswordField from '../../../../../../field/InputPasswordField';
import handleSubmitAction, { ErrorType, getErrorMessage } from '../../../../../../helpers/handleSubmitAction';
import { isAutocompleteRequired, isLessThanAmount, isRequired } from '../../../../../../helpers/validators';
import Money from '../../../../../../services/Money';
import { CurrencyCodeType, SubmitHandler } from '../../../../../../types/app';
import { WithdrawEarningsFormData } from '../../../../../../types/form';
import { UserBankSchema } from '../../../../../../types/schema/bank.schema';
import AlertDialog from '../../../../../app/AlertDialog';
import Dialog from '../../../../../app/Dialog';
import DialogTitle from '../../../../../app/DialogTitle';
import ErrorAlert from '../../../../../app/ErrorAlert';
import LoadingButton from '../../../../../app/LoadingButton';

type WithdrawWalletModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onComplete: () => void;
  currencyCode: CurrencyCodeType;
  slug: string;
  balance: number;
}

const WithdrawWalletModal: FC<WithdrawWalletModalProps> = ({
  isOpen,
  onClose,
  currencyCode,
  slug,
  balance,
  onComplete,
}) => {
  const dispatch = useAppDispatch();

  const {
    data: banks = [],
    isLoading: isBanksLoading,
    isFetching: isBanksFetching,
  } = useGetUserBanksQuery();

  const [
    withdrawEarningsAction,
    { isLoading: isWithdrawing },
  ] = useWithdrawEarningsMutation();

  const [form, setForm] = useState<WithdrawEarningsFormData | undefined>();
  const [openFeeModal, setOpenFeeModal] = useState(false);

  const [getTransferFee, {
    isLoading: isLoadingTransferFee,
    data: transferFee,
  }] = useLazyGetTransferFeeQuery();

  const handleSubmit = useCallback<SubmitHandler<WithdrawEarningsFormData>>(async (values) => {
    setForm(values);
    await getTransferFee({
      amount: values.amount,
      currencyCode,
      password: values.password,
    }).unwrap();
    setOpenFeeModal(true);
  }, [currencyCode, getTransferFee]);

  const handleWithdrawal = useCallback(async () => {
    try {
      await withdrawEarningsAction({
        ...(form!),
        beneficiaryId: (form!.beneficiaryId as unknown as UserBankSchema).id,
        currencyCode,
        slug,
      }).unwrap();
      onComplete();
    } catch (error) {
      const exception = error as ErrorType;

      setOpenFeeModal(false);

      if (exception.status !== 500) {
        dispatch(addSnackbar({
          message: getErrorMessage(exception),
          variant: 'error',
        }));
      }
    }
  }, [currencyCode, dispatch, form, onComplete, slug, withdrawEarningsAction]);

  return (
    <>
      <AlertDialog
        content={`You will be charged ${Money.of(transferFee?.fee, currencyCode).toFormat()} for this withdrawal. 
        You will receive ${Money.of(transferFee?.amount, currencyCode).toFormat()}. 
        Click on "Proceed" button to continue.`}
        isOpen={openFeeModal}
        onClose={() => setOpenFeeModal(false)}
        btnOk={{ label: 'Proceed', isLoading: isWithdrawing, onSubmit: handleWithdrawal }}
        btnClose={{ label: 'Cancel', onClose: () => setOpenFeeModal(false) }}
        persistent
      />

      <Dialog open={isOpen} onClose={onClose} fullWidth maxWidth="sm" persistent={isLoadingTransferFee}>
        <DialogTitle>{`Withdraw ${currencyCode} Earnings`}</DialogTitle>

        <Form<WithdrawEarningsFormData>
          onSubmit={handleSubmitAction(handleSubmit)}
          initialValues={{
            amount: balance.toString(),
            beneficiaryId: '',
            password: '',
            currencyCode,
            slug,
          }}
        >
          {(config) => (
            <>
              <DialogContent>
                <ErrorAlert message={config.errors._server?.message} />
                <InputNumberField
                  fullWidth
                  name="amount"
                  rules={[isRequired, isLessThanAmount(balance, currencyCode)]}
                  label="Amount"
                  helperText={`Max. amount is ${Money.of(balance, currencyCode).toFormat()}`}
                  maxLength={balance}
                />
                <AutocompleteField<UserBankSchema>
                  name="beneficiaryId"
                  options={banks}
                  label="Beneficiary"
                  loading={isBanksLoading || isBanksFetching}
                  getOptionLabel={(option) => (!option.accountName
                    ? ''
                    : `${option.bankName} - ${option.meta.currencyCode} -  ${option.accountNumber} - ${option.accountName}`)}
                  isOptionEqualToValue={(option, value) => option.id === value.id}
                  placeholder="Please select a beneficiary"
                  rules={[isAutocompleteRequired('id')]}
                />

                <InputPasswordField fullWidth name="password" label="Current Password" rules={[isRequired]} />
              </DialogContent>

              <DialogActions>
                <LoadingButton
                  fullWidth
                  loading={config.isLoading}
                  onClick={config.handleSubmit}
                >
                  Submit
                </LoadingButton>
              </DialogActions>
            </>
          )}
        </Form>
      </Dialog>
    </>
  );
};

export default WithdrawWalletModal;
