import { Theme, useMediaQuery } from '@mui/material';
import { ReactElement, VFC } from 'react';

type MobileProps = {
  children: ReactElement;
}

const Mobile: VFC<MobileProps> = ({ children }) => {
  const matches = useMediaQuery<Theme>((theme) => theme.breakpoints.down('md'));

  return matches ? children : null;
};

export default Mobile;
