import { RouteComponentProps } from '@reach/router';
import { navigate } from 'gatsby';
import { ComponentType, FC } from 'react';
import useIsLoggedIn from '../../../plugins/gatsby-plugin-session-check/hooks/useIsLoggedIn';

type PrivateRouteProps = RouteComponentProps & {
  component: ComponentType<RouteComponentProps>;
};

const PrivateRoute: FC<PrivateRouteProps> = ({ component: Component, location, ...rest }) => {
  const isLoggedIn = useIsLoggedIn();

  if (!isLoggedIn && location?.pathname !== '/auth/signin') {
    void navigate(`/auth/signin?redirectTo=${encodeURI(`${window.location.pathname}${window.location.search}`)}`);
    return null;
  }

  return <Component {...rest} location={location} />;
};

export default PrivateRoute;
